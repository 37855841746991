// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-blog-detail-tsx": () => import("/opt/buildhome/repo/src/pages/blog-detail.tsx" /* webpackChunkName: "component---src-pages-blog-detail-tsx" */),
  "component---src-pages-404-tsx": () => import("/opt/buildhome/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-static-page-tsx": () => import("/opt/buildhome/repo/src/pages/static-page.tsx" /* webpackChunkName: "component---src-pages-static-page-tsx" */),
  "component---src-pages-blog-tsx": () => import("/opt/buildhome/repo/src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/buildhome/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/buildhome/repo/.cache/data.json")

